import React, { useState, useEffect, useRef } from "react";
import PrestiageRide from "../assets/PrestiageRide.svg";
import { useParams } from "react-router-dom";
import creditCardType from "credit-card-type";
import LocationInputs2 from "../components/googleInput";
import { useCreditCardValidator } from "react-creditcard-validator";
import { formatCreditCardNumber } from "../utils/Utils";
import valid from "card-validator";
// import MuiPhoneNumber from "material-ui-phone-number";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import GIF from "../assets/Loader2.gif";
import { AiFillPlusCircle } from "react-icons/ai";
import AttachmentLicense from "../assets/attachmentLicense.png";
import AttachmentCard from "../assets/attachmentCard.png";
import AttachmentSelfie from "../assets/attachmentSelfie.png";

const Security = () => {
  const { id } = useParams();
  console.log(id, "iiii");
  const [reservation, setReservation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCheck, setErrorCheck] = useState(false);
  const [errorObject, setErrorObject] = useState({
    cardNumber: "",
  });

  const [images, setImages] = useState({
    cardFront: AttachmentCard,
    cardBack: AttachmentCard,
    license: AttachmentLicense,
    selfie: AttachmentSelfie,
  });

  const cardFrontInputRef = useRef(null);
  const cardBackInputRef = useRef(null);
  const licenseInputRef = useRef(null);
  const selfieInputRef = useRef(null);

  const handleFileChange = (e, key) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((prevImages) => {
          const newImages = { ...prevImages, [key]: reader.result };
          console.log(Object.values(newImages)); // Log the images array to console
          return newImages;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = (inputRef) => {
    inputRef.current.click();
  };

  console.log(images, "iiiiiiiiii");

  const [loaded, setLoaded] = useState(false);

  const [cardState, setCardState] = useState({
    cardHolderName: "",
    cardNumber: "",
    cardType: "",
    cvc: "",
    expirationMonthYear: "",
  });

  function expDateValidate(month, year) {
    // if (Number(year) > 2035) {
    //   return "Expiry Date Year cannot be greater than 2035";
    // }
    return;
  }

  function cleanCardNumber(cardNumber) {
    return cardNumber?.replace(/\D/g, ""); // Remove non-numeric characters
  }

  const handleChangePaymentCard = (e) => {
    const { name, value } = e.target;
    setCardState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCardNumberBlur = (e) => {
    const cleanedCardNumber = cleanCardNumber(cardState.cardNumber);
    let creditCard = valid.number(cleanedCardNumber);
    if (cardState.cardNumber === null || !cardState.cardNumber?.trim()) {
      setErrorObject({
        cardNumber: "Enter a number",
      });
    } else if (creditCard.isValid) {
      setErrorObject({
        cardNumber: "",
      });
    } else {
      setErrorObject({
        cardNumber: "Number is invalid",
      });
    }
  };

  const {
    getCardNumberProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs },
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  console.log(reservation, "reservation");
  console.log(cardState, "ccccccc");

  console.log(errorMessage, "errorMessage");

  const checkValidation = () => {
    let valid = true;
    const mandatoryFields = ["cardNumber", "expirationMonthYear", "cvc"];

    if (!reservation?.reservation?.PaymentMethod) {
      for (const key of mandatoryFields) {
        if (!cardState[key]) {
          alert("Please fill all required fields");
          valid = false;
          break; // Exit the loop immediately
        }
      }
    }

    return valid;
  };

  // useEffect(() => {
  //   getSingleReservation();
  // }, []);

  // const getSingleReservation = async () => {
  //   try {
  //     setLoaded(true);
  //     const result = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/api/email-template/payment-link-verification/${id}`
  //     );

  //     console.log(result, "result");
  //     if (result?.data?.success === true) {
  //       setReservation(result?.data);
  //     } else if (result?.data?.success === false) {
  //       setErrorCheck(true);
  //       setErrorMessage(result?.data?.message);
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   } finally {
  //     setLoaded(false);
  //   }
  // };

  const handleSubmit = async () => {
    const result = checkValidation();

    // if (result) {
    //   try {
    //     setLoaded(true);
    //     const input = cardState?.expirationMonthYear;
    //     const [month, year] = input.split(" / ").map((item) => item.trim());

    //     // Dynamically determine the century prefix
    //     const currentYear = new Date().getFullYear();
    //     const centuryPrefix = Math.floor(currentYear / 100) * 100;

    //     // Combine the century prefix with the year
    //     const fullYear = centuryPrefix + parseInt(year, 10);

    //     const formattedDate = `${fullYear}-${month}`;
    //     let body = {};

    //     if (reservation?.type === "Authorize & Capture") {
    //       body = {
    //         createTransactionRequest: {
    //           merchantAuthentication: {
    //             name: "95ExP7ceP",
    //             transactionKey: "5F9W4gGTL9w82eeC",
    //           },
    //           refId: "123456",
    //           transactionRequest: {
    //             transactionType: "authCaptureTransaction",
    //             amount: reservation?.amount,
    //             payment: {
    //               creditCard: {
    //                 cardNumber: cardState?.cardNumber?.replace(/\s+/g, ""),
    //                 expirationDate: formattedDate,
    //                 cardCode: cardState?.cvc,
    //               },
    //             },
    //             billTo: {
    //               firstName: cardState?.cardHolderName,
    //               address: cardState.billingAddress,
    //               city: cardState?.city,
    //               state: cardState?.state,
    //               zip: cardState?.zipcode,
    //               country: cardState?.country,
    //             },
    //             transactionSettings: {
    //               setting: {
    //                 settingName: "testRequest",
    //                 settingValue: "false",
    //               },
    //             },
    //             processingOptions: {
    //               isSubsequentAuth: "true",
    //             },
    //             subsequentAuthInformation: {
    //               originalNetworkTransId: "123456789NNNH",
    //               originalAuthAmount: reservation?.amount,
    //               reason: "resubmission",
    //             },
    //             authorizationIndicatorType: {
    //               authorizationIndicator: "final",
    //             },
    //           },
    //         },
    //       };
    //     } else if (reservation?.type === "Authorize") {
    //       body = {
    //         createTransactionRequest: {
    //           merchantAuthentication: {
    //             name: "95ExP7ceP",
    //             transactionKey: "5F9W4gGTL9w82eeC",
    //           },
    //           refId: "123456",
    //           transactionRequest: {
    //             transactionType: "authOnlyTransaction",
    //             amount: reservation?.amount,
    //             payment: {
    //               creditCard: {
    //                 cardNumber: cardState?.cardNumber?.replace(/\s+/g, ""),
    //                 expirationDate: formattedDate,
    //                 cardCode: cardState?.cvc,
    //               },
    //             },
    //             billTo: {
    //               firstName: cardState?.cardHolderName,
    //               address: cardState.billingAddress,
    //               city: cardState.city,
    //               state: cardState.state,
    //               zip: cardState.zipcode,
    //               country: cardState.country,
    //             },
    //             processingOptions: {
    //               isSubsequentAuth: "true",
    //             },
    //             subsequentAuthInformation: {
    //               originalNetworkTransId: "123456789NNNH",
    //               originalAuthAmount: reservation?.amount,
    //               reason: "resubmission",
    //             },
    //             authorizationIndicatorType: {
    //               authorizationIndicator: "pre",
    //             },
    //           },
    //         },
    //       };
    //     }

    //     const dateTime = new Date();
    //     const postedYear = dateTime.getFullYear();
    //     const postedMonth = dateTime.getMonth() + 1; // Months are zero-based, so add 1
    //     const day = dateTime.getDate();

    //     let hours = dateTime.getHours();
    //     const minutes = dateTime.getMinutes();

    //     const ampm = hours >= 12 ? "PM" : "AM";
    //     hours = hours % 12;
    //     hours = hours ? hours : 12; // the hour '0' should be '12'

    //     // Format the date as MM-DD-YYYY
    //     const currentDate = `${String(postedMonth).padStart(2, "0")}-${String(
    //       day
    //     ).padStart(2, "0")}-${postedYear}`;

    //     // Format the time as hh:mm AM/PM
    //     const currentTime = `${String(hours).padStart(2, "0")}:${String(
    //       minutes
    //     ).padStart(2, "0")} ${ampm}`;

    //     let finalData = {
    //       TransactionRequest: {
    //         ...body,
    //       },
    //       data: {
    //         ...cardState,
    //         type: reservation?.type,
    //         transactionId: reservation?.transaction,
    //         paymentMethod: "CC",
    //         postedDate: currentDate,
    //         postedTime: currentTime,
    //       },
    //     };

    //     console.log(finalData, "testing");

    //     let transactionResult;

    //     const initialState = {
    //       billingAddress: "",
    //       cardHolderName: "",
    //       cardNumber: "",
    //       cardType: "",
    //       city: "",
    //       country: "",
    //       cvc: "",
    //       expirationMonthYear: "",
    //       state: "",
    //       street: "",
    //       transactionId: "",
    //       type: "",
    //       zipcode: "",
    //     };

    //     if (reservation?.type === "Authorize & Capture") {
    //       transactionResult = await axios.post(
    //         `${process.env.REACT_APP_API_URL}/api/transaction/authorize-capture/payment-link/${reservation?.booking?._id}`,
    //         finalData
    //       );
    //       console.log(transactionResult, finalData, "acccc");
    //     } else if (reservation?.type === "Authorize") {
    //       transactionResult = await axios.post(
    //         `${process.env.REACT_APP_API_URL}/api/transaction/authorize/payment-link/${reservation?.booking?._id}`,
    //         finalData
    //       );
    //       console.log(transactionResult, finalData, "aaaa");
    //     }
    //     if (transactionResult?.data?.success === true) {
    //       // setOpen(true);
    //       setCardState(initialState);
    //     } else if (
    //       transactionResult?.data?.success === false &&
    //       transactionResult?.data?.status === "expired"
    //     ) {
    //       // setBasicModalOpen(true);
    //     }
    //   } catch (error) {
    //     console.log(error, "submiterror");
    //   } finally {
    //     setLoaded(false);
    //   }
    // }
  };

  return (
    <div className="lg:py-2 py-0 bg-white">
      <Backdrop
        sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaded}
      >
        {loaded && (
          <div style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                color: "white",
                top: "40px",
                left: "-50px",
                fontWeight: "600",
                width: "800%",
              }}
            >
              {/* {store?.text} */}
            </span>
            <img
              src={GIF}
              alt="Loading"
              style={{ width: "90px", height: "90px" }}
            />
          </div>
        )}
      </Backdrop>

      <>
        <div className="mx-auto max-w-3xl border border-black rounded lg:px-8 px-4 py-4">
          <div className="">
            <h1 className="text-lg font-bold">
              <img src={PrestiageRide} alt="" />
            </h1>
            <div className="mt-2 text-[#2B3252]">
              <a href="tel:+ 1 (888) 346 9886">
                <p>Tel: + 1 (888) 346 9886</p>
              </a>
              <a href="mailto:contact@prestigeride.com">
                <p>Email: contact@prestigeride.com</p>
              </a>
            </div>
          </div>
          <div className="mt-4">
            <h1 className="bg-gray-200 font-semibold text-[#2B3252] px-4 py-2">
              Attachments for Reservation # 2125172
            </h1>
          </div>

          <div className="py-3 text-[#2B3252] grid grid-cols-2 gap-4">
            {/* Credit Card (Front) */}
            <div>
              <h2 className="font-semibold">Credit Card (Front)</h2>
              <div className="mt-3 rounded-xl overflow-hidden bg-[#D9D9D9]">
                <img
                  src={images.cardFront}
                  alt="Credit Card Front"
                  className="w-full h-48 object-contain"
                />
              </div>
              <p
                className="flex items-center mt-2 space-x-1 text-sm cursor-pointer"
                onClick={() => handleUploadClick(cardFrontInputRef)}
              >
                <span className="font-bold">Upload</span>
                <span className="text-[#D5AF34] text-xl">
                  <AiFillPlusCircle />
                </span>
              </p>
              <input
                type="file"
                ref={cardFrontInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "cardFront")}
              />
            </div>
            {/* Credit Card (Back) */}
            <div>
              <h2 className="font-semibold">Credit Card (Back)</h2>
              <div className="mt-3 rounded-xl overflow-hidden bg-[#D9D9D9]">
                <img
                  src={images.cardBack}
                  alt="Credit Card Back"
                  className="w-full h-48 object-contain"
                />
              </div>
              <p
                className="flex items-center mt-2 space-x-1 text-sm cursor-pointer"
                onClick={() => handleUploadClick(cardBackInputRef)}
              >
                <span className="font-bold">Upload</span>
                <span className="text-[#D5AF34] text-xl">
                  <AiFillPlusCircle />
                </span>
              </p>
              <input
                type="file"
                ref={cardBackInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "cardBack")}
              />
            </div>
            {/* Driving License */}
            <div>
              <h2 className="font-semibold">Driving License</h2>
              <div className="mt-3 rounded-xl overflow-hidden bg-[#D9D9D9]">
                <img
                  src={images.license}
                  alt="Driving License"
                  className="w-full h-48 object-contain"
                />
              </div>
              <p
                className="flex items-center mt-2 space-x-1 text-sm cursor-pointer"
                onClick={() => handleUploadClick(licenseInputRef)}
              >
                <span className="font-bold">Upload</span>
                <span className="text-[#D5AF34] text-xl">
                  <AiFillPlusCircle />
                </span>
              </p>
              <input
                type="file"
                ref={licenseInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "license")}
              />
            </div>
            {/* Selfie */}
            <div>
              <h2 className="font-semibold">Selfie</h2>
              <div className="mt-3 rounded-xl overflow-hidden bg-[#D9D9D9]">
                <img
                  src={images.selfie}
                  alt="Selfie"
                  className="w-full h-48 object-contain"
                />
              </div>
              <p
                className="flex items-center mt-2 space-x-1 text-sm cursor-pointer"
                onClick={() => handleUploadClick(selfieInputRef)}
              >
                <span className="font-bold">Upload</span>
                <span className="text-[#D5AF34] text-xl">
                  <AiFillPlusCircle />
                </span>
              </p>
              <input
                type="file"
                ref={selfieInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "selfie")}
              />
            </div>
          </div>

          <div className="py-2">
            <h2 className="bg-gray-200 font-semibold text-[#2B3252] px-4 py-2">
              Payment Information
            </h2>
          </div>
          <div className="py-2">
            <div className="">
              <label
                htmlFor=""
                className="block text-[13px] font-bold text-[#2B3252]"
              >
                CardHolder Name
              </label>
              <div>
                <input
                  type="text"
                  name="cardHolderName"
                  id="cardHolderName"
                  // placeholder="Name on Card"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setCardState((prev) => ({
                      ...prev,
                      cardHolderName: inputValue,
                    }));
                  }}
                  value={cardState.cardHolderName}
                  className="block w-full h-[34px] px-2 text-[14px] rounded border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-y-0 gap-y-2 gap-x-2 mt-2">
              <div>
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  Card Number<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="cardNumber"
                    data-testid="cardNumber"
                    id="cardNumber"
                    placeholder="0000 0000 0000 0000"
                    className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                    value={cardState.cardNumber}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Limit input to 16 characters
                      if (inputValue.length <= 19) {
                        var visaCards = creditCardType(inputValue || []);
                        // Update card state
                        setCardState((prev) => ({
                          ...prev,
                          cardNumber: formatCreditCardNumber(inputValue),
                          cardType:
                            visaCards && visaCards?.length > 0
                              ? visaCards[0]?.type
                              : "",
                        }));
                        setErrorObject({
                          cardNumber: "",
                        });
                      }
                    }}
                    onBlur={handleCardNumberBlur}
                  />
                  <small className="text-red-500 text-[14px]">
                    {errorObject.cardNumber ? errorObject.cardNumber : null}
                  </small>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-x-2">
                <div className="">
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    Expiry Month/Year<span className="text-red-600">*</span>
                  </label>
                  <div className="">
                    <input
                      {...getExpiryDateProps({
                        onChange: (e) => {
                          setCardState((prev) => ({
                            ...prev,
                            expirationMonthYear: e.target.value,
                          }));
                        },
                      })}
                      type="text"
                      name="expirationMonthYear"
                      id="cardExpiration"
                      data-testid="cardExpiration"
                      placeholder="MM/YY"
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      value={cardState.expirationMonthYear}
                    />
                    <small className="text-red-500 text-[14px]">
                      {erroredInputs.expiryDate && erroredInputs.expiryDate}
                    </small>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    CVC<span className="text-red-600">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="cvc"
                      id="cardSecurityCode"
                      data-testid="cardSecurityCode"
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      value={cardState.cvc}
                      maxLength={4}
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        value = value.substring(0, 4); // Ensure it doesn't exceed 4 digits
                        setCardState((prev) => ({
                          ...prev,
                          cvc: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <h2 className="bg-gray-200 font-semibold text-[#2B3252] px-4 py-2">
              Billing Information
            </h2>
          </div>
          <div className="py-2">
            <div className="mt-1 grid grid-cols-2 gap-x-2">
              <div>
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  Billing Address
                </label>
                <div className="">
                  <LocationInputs2
                    setCardState={setCardState}
                    cardState={cardState}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  Street Address
                </label>
                <div className="">
                  <input
                    type="text"
                    name="street"
                    id="text"
                    className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                    onChange={handleChangePaymentCard}
                    value={cardState.street}
                  />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-x-2 lg:gap-y-0 gap-y-2 mt-2">
              <div>
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  City
                </label>
                <div className="">
                  <input
                    type="text"
                    name="city"
                    id="text"
                    className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                    onChange={handleChangePaymentCard}
                    value={cardState.city}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  State/Province
                </label>
                <div className="">
                  <input
                    type="text"
                    name="state"
                    id="text"
                    className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                    onChange={handleChangePaymentCard}
                    value={cardState.state}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  Zip/Post
                </label>
                <div className="">
                  <input
                    type="text"
                    name="zipcode"
                    id="text"
                    className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                    onChange={handleChangePaymentCard}
                    value={cardState.zipcode}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  Country
                </label>
                <div className="">
                  <input
                    type="text"
                    name="country"
                    id="text"
                    className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                    onChange={handleChangePaymentCard}
                    value={cardState.country}
                  />
                </div>
              </div>
            </div>
            {/* <div className="grid grid-cols-2 gap-x-2 mt-2">
              <div className="">
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  CardHolder Email
                </label>
                <div>
                  <input
                    type="text"
                    name="cardHolderEmail"
                    id="cardHolderEmail"
                    // placeholder="Cardholder Email"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setCardState((prev) => ({
                        ...prev,
                        cardHolderEmail: inputValue,
                      }));
                    }}
                    value={cardState.cardHolderEmail}
                    className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                  />
                </div>
              </div>
              <div className="">
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  CardHolder Phone
                </label>
                <div className="border border-black rounded-[4px] h-[34px]">
                  <MuiPhoneNumber
                    defaultCountry={"us"}
                    name="cardHolderPhone"
                    value={cardState.cardHolderPhone}
                    className="block w-full h-[30px] text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                    onChange={(value) => {
                      setCardState((prev) => ({
                        ...prev,
                        cardHolderPhone: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div> */}
            <div className="mt-2">
              <button
                className="w-full rounded py-1.5 bg-[#D5AF34] text-white text-lg font-semibold"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <div className="mt-2">
              <p className="text-[#2B3252]">
                By continuing you agree to our{" "}
                <a
                  href="https://www.prestigeride.com/privacy-and-cookies-policy"
                  target="_blank"
                  className="text-[#D5AF34] font-semibold"
                >
                  privacy policy
                </a>{" "}
                and{" "}
                <a
                  href="https://www.prestigeride.com/terms-and-condition"
                  target="_blank"
                  className="text-[#D5AF34] font-semibold"
                >
                  terms and conditions.
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Security;
