export const formatCreditCardNumber = (number) => {
  // Remove all existing spaces and non-numeric characters
  const cleanedNumber = number.replace(/\D/g, "");

  // Determine the appropriate grouping based on the card number length
  const isAmex =
    cleanedNumber.startsWith("34") || cleanedNumber.startsWith("37");

  let formattedNumber;

  if (isAmex) {
    // American Express uses a 4-6-5 grouping
    formattedNumber = cleanedNumber
      .replace(/(\d{4})(\d{6})(\d{0,5})/, "$1 $2 $3")
      .trim();
  } else {
    // Most other cards use a 4-4-4-4 grouping
    formattedNumber = cleanedNumber
      .replace(/(\d{4})(\d{4})(\d{4})(\d{0,4})/, "$1 $2 $3 $4")
      .trim();
  }

  return formattedNumber;
};
