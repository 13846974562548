import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { LocationOn } from "@mui/icons-material";
import axios from "axios";

const GOOGLE_MAPS_API_KEY = "AIzaSyDIeClNpDYxb_5l6hX-MeiF13IWDQ-CGYM";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function LocationInputs({ setCardState, cardState }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    if (!cardState.billingAddress) {
      setValue(null);
      setInputValue("");
    }
  }, [cardState?.billingAddress]);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleGeocode = async (Address) => {
    if (Address) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: Address,
              key: GOOGLE_MAPS_API_KEY,
            },
          }
        );

        console.log(response, "responseeeeee");
        const { results } = response.data;

        if (results && results.length > 0) {
          let city = "",
            state = "",
            zipcode = "",
            streetNumber = "",
            street = "",
            country = "";
          for (let add_part of results[0].address_components) {
            if (add_part.types.includes("administrative_area_level_1")) {
              state = add_part.long_name;
            }

            if (
              add_part.types.includes("postal_town") ||
              add_part.types.includes("locality")
            ) {
              city = add_part.long_name;
            }

            if (add_part.types.includes("postal_code")) {
              zipcode = add_part.long_name;
            }
            if (add_part.types.includes("street_number")) {
              streetNumber = add_part.long_name;
            }

            if (add_part.types.includes("route")) {
              street = streetNumber + " " + add_part.long_name;
            }

            if (add_part.types.includes("country")) {
              country = add_part.long_name;
            }
          }
          // const { lat, lng } = results[0].geometry.location;
          setCardState((prev) => ({
            ...prev,
            billingAddress: Address,
            city: city,
            state: state,
            zipcode: zipcode,
            street: street,
            country: country,
          }));
        } else {
          console.error("No results found");
        }
      } catch (error) {
        console.error("Error fetching geocoding data", error);
      }
    } else {
      setCardState((prev) => ({
        ...prev,
        billingAddress: "",
        city: "",
        state: "",
        zipcode: "",
        street: "",
        country: "",
      }));
    }
  };

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        handleGeocode(newValue?.description || "");
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setCardState((prev) => ({
          ...prev,
          billingAddress: newInputValue,
        }));
      }}
      renderInput={(params) => (
        <div style={{ position: "relative" }}>
          <LocationOn
            color="action"
            style={{
              height: "17px",
              position: "absolute",
              top: "50%",
              left: 10,
              transform: "translateY(-50%)",
            }}
          />
          <TextField
            {...params}
            variant="outlined"
            className="customAutocomplete"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                },
                // "&:hover fieldset": {
                //   borderColor: "black",
                // },
                "&.Mui-focused fieldset": {
                  border: "1px solid black",
                },
              },
            }}
            // placeholder={"Enter your billing Address"}
          />
        </div>
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
