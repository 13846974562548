import React, { useState } from "react";
import Security from "./containers/Security";
import { Routes, Route, BrowserRouter } from "react-router-dom";

const App = () => {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/attachments/:id" element={<Security />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
